@use '@angular/material' as mat;
@use './sb-theme';

@include mat.all-component-typographies();
@include mat.elevation-classes();
@include mat.app-background();

html {
  color-scheme: light;
  // color-scheme: light dark;

  // Apply the light theme by default

  @include mat.all-component-themes(sb-theme.$light-theme);
  @include mat.typography-hierarchy(sb-theme.$light-theme);
  @include mat.system-level-colors(sb-theme.$light-theme);
  @include mat.system-level-typography(sb-theme.$light-theme);

  // // Apply the dark theme only when the user prefers dark themes.
  // @media (prefers-color-scheme: dark) {
  //   // Use the `-color` mixins to only apply color styles without reapplying the same
  //   // typography and density styles.
  //   @include mat.all-component-themes(sb-theme.$dark-theme);
  //   @include mat.typography-hierarchy(sb-theme.$dark-theme);
  //   @include mat.system-level-colors(sb-theme.$dark-theme);
  //   @include mat.system-level-typography(sb-theme.$dark-theme);
  // }
}

body {
  margin: 0;
}
